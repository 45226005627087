<template>
  <b-tr>
    <b-td>
      <div>
        <badge
          :status="item.status"
          :statuses="statuses"
        />
      </div>
    </b-td>
    <b-td>
      {{opDate}}
    </b-td>
    <b-td>
      <div>
        {{description}}
        <div v-if="!isProduct">
          <em>{{topupVal}}</em>
        </div>
      </div>
      <div>
        <b-button
          class="btn-info mt-1 px-2"
          size="sm"
          @click="downloadFile"
          v-if="hasDownload"
          v-b-tooltip.hover
          title="Download"
        >
          <font-awesome-icon icon="cloud-download-alt" class="fa-fw"></font-awesome-icon>
        </b-button>
      </div>
    </b-td>
    <b-td class="white-space-nowrap text-right">
      <span :class="valClasses">
        {{transVal}}
      </span>
    </b-td>
  </b-tr>
</template>
<script>
import { formatDateTime, formatCurrency } from '@/utils/formatterHelper';
import { getEntityApiBase, openPrivateDocument } from '@/utils/documents';
import { isNotEmpty, isNotExist } from '@/utils/validators';

const Badge = () => import('@/components/helpers/Badge.vue');

export default {
  name: 'TransactionRow',
  components: { Badge },
  props: {
    item: {
      status: String,
      createdAt: Date,
      lastModified: Date,
      info: {
        feedbackName: String,
        feedbackType: String,
        feedbackQuestion: String,
        labelFeedbackType: String,
        labelFeedbackQuestion: String,
      },
      singleResponse: Boolean,
      totalResponses: Number,
    },
    statuses: String,
  },
  data() {
    return {
      document: null,
      apiBase: getEntityApiBase('TRANSACTION'),
    };
  },
  computed: {
    currentName() {
      return this.$store.getters['auth/currentName'];
    },
    notAvailableMsg() {
      return 'N.D.';
    },
    opDate() {
      return formatDateTime(this.item.operationDate) || this.notAvailableMsg;
    },
    description() {
      return this.item.description || this.notAvailableMsg;
    },
    isProduct() {
      return this.item.creditsUsed !== null;
    },
    valClasses() {
      if (this.item.status === 'REFUNDED') return 'text-success';
      if (this.isProduct) {
        return 'text-danger';
      }
      return 'text-success';
    },
    transVal() {
      let val;
      if (this.isProduct) {
        val = this.item.creditsUsed !== null
          ? formatCurrency(this.item.creditsUsed.amount, 'it', this.item.creditsUsed.currency)
          : this.notAvailableMsg;
      } else {
        val = this.item.creditsPurchased !== null
          ? formatCurrency(this.item.creditsPurchased.amount, 'it', this.item.creditsPurchased.currency)
          : this.notAvailableMsg;
      }
      return val;
    },
    topupVal() {
      const val = this.item.transactionAmount !== null
        ? formatCurrency(this.item.transactionAmount.amount, 'it', this.item.transactionAmount.currency)
        : this.notAvailableMsg;
      return `Costo: ${val}`;
    },
    hasDownload() {
      return isNotEmpty(this.item?.creditsPurchased) && isNotEmpty(this.document);
    },
  },
  mounted() {
    this.fetchInvoiceDoc();
  },
  methods: {
    fetchInvoiceDoc() {
      if (isNotExist(this.item?.creditsPurchased)) return;

      console.log('trx row fetchDoc', this.item);
      this.$store.dispatch('documents/loadDocuments', {
        entityClass: 'TRANSACTION',
        entityId: this.item.transactionId,
      }).then((docs) => {
        if (docs) {
          const docInv = docs.find((doc) => doc.documentInfo.documentType === 'INVOICE');
          if (docInv) {
            this.document = docInv;
          }
        }
      });
    },
    downloadFile() {
      if (this.document) {
        openPrivateDocument(this.document.documentId, this.apiBase);
      }
    },
  },
};
</script>

<style scoped>

</style>
